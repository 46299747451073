<template>
    <v-card
      color="transparent"
      elevation="0"
    >
      <div>{{ title }}</div>
      <h1 class="defaultText--text font-weight-medium">{{ $n(totalBalance, 'floatCurrency') }}</h1>
    </v-card>
</template>

<script>
export default {
  name: 'AccountBalanceCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    totalBalance: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>
